/* globals tribe_js_config */
/* Tweaks to the Tribe Events sidebar on single events */
export default function tribeSingleEventTweaks() {
  const baseUrl = tribe_js_config.events_base || '/events/'
  const eventBar = document.querySelector('#tribe-events-bar')
  const dateInput = document.querySelector('#tribe-bar-date')
  const filterForm = document.querySelector('#tribe-bar-form')

  // Transform the form to "GET" to allow regular form submits
  filterForm.setAttribute('method', 'get')
  filterForm.setAttribute('action', `${baseUrl}list/`)

  // Redirect to the appropriate view after clicking the view selector
  eventBar.addEventListener('click', event => {
    const viewType = event.target.parentNode.dataset.view
    if (viewType) window.location.href = `${baseUrl}${viewType}/`
  })

  // Submit the form after the dateInput is updated
  // Listen for `blur` because `change` is not triggered by the date picker
  // Wait 300ms for the date value to be updated on the input
  dateInput.addEventListener('blur', event => {
    const oldDate = event.target.value
    setTimeout(() => {
      const newDate = event.target.value
      if (newDate !== oldDate) filterForm.submit()
    }, 300)
  })
}
