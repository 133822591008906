import Flickity from 'flickity-imagesloaded'

// Flickity - equal cell height hack
// https://codepen.io/desandro/pen/ZXEGVq

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize')
}

Flickity.createMethods.push('_createResizeClass')

var resize = Flickity.prototype.resize
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize')
  resize.call(this)
  this.element.classList.add('flickity-resize')
}

export default Flickity
