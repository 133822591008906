/**
 * Add dynamic sidebar elements linking to interior page headers
 * @param {HTMLElement} sidebar
 */
export default function initSidebarNav(sidebar) {
  const headers = document.querySelectorAll('[data-section-header]')
  const activePageItem = sidebar.querySelector('.current-menu-item')
  if (!(activePageItem && headers.length > 0)) return

  const submenuList = document.createElement('ul')
  submenuList.classList.add('submenu')
  activePageItem.appendChild(submenuList)
  headers.forEach(header => {
    submenuList.insertAdjacentHTML(
      'beforeend',
      `<li><a href="#${header.id}">${header.textContent}</a></li>`
    )
  })
}
