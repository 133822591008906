/* globals HTMLElement */

/**
 * Scroll sticky elements when they overflow the viewport
 * Is conditionally activated by media queries
 * @param {String|HTMLElement} element
 * @param {matchMedia} mediaQuery
 *
 * @see https://github.com/guoyunhe/sticky-scroller
 */

export default class ResponsiveStickyScroller {
  constructor(element, mediaQuery) {
    this.newScrollPosition = 0
    this.oldScrollPosition = 0
    this.ticking = false
    this.mediaQuery = mediaQuery

    if (typeof element === 'string') {
      this.element = document.querySelector(element)
    } else if (element instanceof HTMLElement) {
      this.element = element
    } else {
      console.error('StickyScroller: element is required.')
      return
    }
    window.addEventListener('scroll', this.onWindowScroll.bind(this))
    this.translate()
  }

  onWindowScroll() {
    this.newScrollPosition = window.scrollY

    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.translate()
        this.ticking = false
        this.oldScrollPosition = this.newScrollPosition
      })

      this.ticking = true
    }
  }

  translate() {
    // Reset position if the media query doesn't match
    if (!this.mediaQuery.matches) {
      this.element.scrollTop = 0
      this.element.style.overflowY = null
      return
    }

    const parentRect = this.element.parentElement.getBoundingClientRect()
    const distance = this.newScrollPosition - this.oldScrollPosition
    // Do not scroll up before sticky period
    if (parentRect.top > 0 && distance > 0) {
      return
    }
    // Do not scroll down after sticky period
    if (parentRect.bottom < window.innerHeight && distance < 0) {
      return
    }
    this.element.style.overflowY = 'hidden'
    this.element.scrollTop = this.element.scrollTop + distance
  }
}
