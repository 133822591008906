/**
 * Transform the date archive markup generated by WordPress into lists
 * grouped by year
 * @param {HTMLElement} nav <ul> generated by WordPress with all date links
 */
export default function initDateNav(nav) {
  let prevYear = null
  let yearList

  nav.querySelectorAll('li').forEach(item => {
    const link = item.querySelector('a')
    const [month, year] = link.textContent.split(' ')

    // Generate a new <ul> every time the year changes
    if (prevYear !== year) {
      nav.insertAdjacentHTML(
        'beforeend',
        `
        <li>
          <a href="/${year}" data-houdini-toggle="year-${year}">${year}</a>
          <ul id="year-${year}" data-houdini="animated" class="submenu"></ul>
        </li>
        `
      )
      yearList = nav.querySelector(`#year-${year}`)
      prevYear = year
    }

    // Append each item to the current year list
    link.textContent = month
    yearList.appendChild(item) // IE11 requires appendChild, not append
  })
}
