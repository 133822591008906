import focusVisible from 'focus-visible'
import Houdini from 'houdinijs'
import '../scss/index.scss'
import initColumns from './columns'
import initDateNav from './date-nav'
import Flickity from './flickity'
import initHoudiniAnimations from './houdiniAnimations'
import ResponsiveStickyScroller from './responsive-sticky-scroller'
import initSidebarNav from './sidebar-nav'
import tribeSingleEventTweaks from './tribe-single-event'

const activeClass = '-active'
const root = document.documentElement
const desktopMQ = window.matchMedia('(min-width: 992px)')
const tabletMQ = window.matchMedia('(min-width: 768px)')
const header = document.querySelector('[data-sticky="header"]')
const sidebar = document.querySelector('[data-sticky="sidebar"]')
const dateNav = document.querySelector('#sidebar-date')

// Update <html> class to indicate JS is enabled
root.classList.remove('no-js')
root.classList.add('js')

// Polyfill Array.from if needed
if (!Array.from) Array.from = object => [].slice.call(object)

// Polyfill NodeList.forEach
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}

// Tweak the sidebar on single events
if (document.querySelector('body').classList.contains('single-tribe_events')) {
  tribeSingleEventTweaks()
}

// Initialize all Flickity instances with a common config
// This will match any elements with a data-slider attribute
const sliders = document.querySelectorAll('[data-slider]')
sliders.forEach(
  slider =>
    new Flickity(slider, {
      pageDots: false,
      groupCells: true,
      cellAlign: 'left',
      imagesLoaded: true,
      arrowShape: {
        x0: 25,
        x1: 55,
        y1: 50,
        x2: 60,
        y2: 50,
        x3: 30
      }
    })
)

const verticalSlider = document.querySelectorAll('[data-vertical-slider]')
verticalSlider.forEach(
  slider =>
    new Flickity(slider, {
      pageDots: true,
      groupCells: false,
      cellAlign: 'right',
      prevNextButtons: false
    })
)

// Initialize expanding columns
const columns = document.querySelectorAll('[data-column-group]')
columns.forEach(column => {
  initColumns(column)
})

// Add smart scrolling the site header and sidebar
if (header) header.scroller = new ResponsiveStickyScroller(header, desktopMQ)
if (sidebar) sidebar.scroller = new ResponsiveStickyScroller(sidebar, tabletMQ)

// Add dynamic sidebar nav elements
if (sidebar) initSidebarNav(sidebar)

// Group archives by date
if (dateNav) initDateNav(dateNav)

// Initialize all collapsibles with Houdini
const houdiniInstance = new Houdini('[data-houdini]', {
  expandedClass: activeClass
})

// Initialize houdini animations on msx-height
initHoudiniAnimations()

// Expose to the window for use in templates
window.houdiniInstance = houdiniInstance
window.Flickity = Flickity
window.focusVisible = focusVisible
