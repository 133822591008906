export default function initColumns(element) {
  element.addEventListener('click', event => {
    const columnGroup = element.dataset.columnGroup
    const columnGroupItems = document.querySelectorAll(
      `[data-column-group='${columnGroup}']`
    )

    if (element.classList.contains('-large')) {
      columnGroupItems.forEach(column => {
        column.classList.remove('-small')
      })
      element.classList.remove('-large')
    } else {
      columnGroupItems.forEach(column => {
        column.classList.remove('-large')
        column.classList.add('-small')
      })

      element.classList.remove('-small')
      element.classList.add('-large')
    }
  })
}
